body {
	margin: 0;
	font-family: Arial, sans-serif;
}

.layout {
	width: 1024px;
	margin: 2rem auto;

	& > .notification-global {
		margin-top: -1rem;
		margin-bottom: 1rem;
	}

	header {
		.flex {
			justify-content: space-between;

			.logo {
				&:focus-visible {
					outline-offset: .5rem;
				}
			}

			[role="search"] {
				label {
					font-size: $font-regular-larger;
					color: $color-form-control;
	
					.placeholder {
						display: inline-block;
						position: relative;
						left: 3.5rem; /* Adjust the value as needed */
						top: 50%;
					}

					[role="searchbox"] {
						width: 15rem;
						border-width: 2px;
						border-radius: .25rem;
						padding: .25rem;
						padding-left: 3.25rem;
						font-size: $font-regular-larger;
						color: $color-form-control;
					}
				}

				button[type="submit"] {
					width: 2rem;
					height: 2rem;
					margin-left: .25rem;
					padding: 0;
					font-size: $font-regular-larger;

					&:focus-visible {
						outline: 2.5px solid $color-form-control;
						border-radius: .25rem;
					}

					svg {
						path {
							fill: $color-form-control;
						}
					}
				}
			}
		}

		.menu-top {
			background: $color-text-primary;
			margin-top: 1rem;
			color: white;

			ul {
				margin: 0;
			}

			a {
				text-decoration: none;
				color: inherit;
			}

			button {
				background: transparent;
				border-width: 0;
			}

			& > ul {
				display: flex;
				align-items: center;

				& > li {
					flex-grow: 1;
					flex-basis: 0;
					padding-left: 0;
					background: $color-primary-gradient;
					font-weight: bold;
					text-align: center;

					& > a,
					& > button {
						display: inline-block;
						width: 100%;
						padding: .75rem 0;

						&:focus-visible {
							position: relative;
							z-index: 11;
						}
					}

					button[aria-expanded="true"] {
						background: $color-primary-gradient-reverse;
					}

					button[aria-expanded="false"]:hover + [role="group"],
					button[aria-expanded="true"] + [role="group"],
					[role="group"]:hover {
						display: block;
					}

					[role="group"] {
						display: none;
						z-index: 10;
						position: absolute;
						background: $color-primary;
						text-align: left;

						ul {
							border-top: 1px solid lighten($color-primary, 10%);

							li {
								padding-left: 0;

								button,
								a {
									display: inline-block;
									width: 100%;
									padding: .5rem;
									font-weight: normal;

									&:focus,
									&:hover {
										background: lighten($color-primary, 5%);
									}
								}
							}
						}
					}
				}

				> li + li {
					border-left: 2px solid $color-layout-separator;
				}
			}
		}
	}

	.banner {
		border-bottom: 2px solid $color-layout-separator;
	}

	main {
		border-bottom: 2px solid $color-text-primary;

		aside {
			padding: 0 1rem;
			margin-bottom: 2rem;
			background: $color-primary;
			border-radius: .5rem;
			color: white;

			h1,
			h2,
			h3,
			p,
			a {
				color: white;
			}
		}

		input[type="text"],
		input[type="email"],
		select {
			min-width: 250px;
		}
	}

	footer {
		.column {
			align-self: flex-start;
			padding: 1.5rem;

			:first-child {
				margin-top: 0;
			}

			:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			li {
				line-height: 1.5;
			}
		}

		.powered-by {
			margin-top: 3rem;
			text-align: center;

			h3 {
				margin: 0;
				margin-bottom: .5rem;
				font-size: $font-regular-smallest;
				font-weight: bold;
				color: lighten(black, 40%);
			}

			svg {
				g {
					fill: lighten(black, 50%);
				}
			}
		}
	}
}
