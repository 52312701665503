@charset "UTF-8";
* {
  box-sizing: border-box;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Mobile-first approach */
.mobile-only {
  display: block; /* Show on mobile */
}

.desktop-only {
  display: none; /* Hide on mobile */
}

/* Show .desktop-only and hide .mobile-only on larger screens */
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: block;
  }
}
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

.sr-only:focus,
.sr-only:active {
  z-index: 1;
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

*:focus-visible {
  outline-offset: 1px;
}

h1, h2, h3 {
  color: #115588;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

p,
.p {
  margin: 1.12em 0;
  line-height: 1.5;
}

ul,
ol {
  padding-left: 1rem;
}
ulul,
olul {
  list-style: circle;
}
ul.unstyled-list,
ol.unstyled-list {
  list-style: none;
  padding: 0;
}
ul.wide-spaced li,
ol.wide-spaced li {
  margin: 0.5rem 0;
}
ul > li,
ol > li {
  padding-left: 0.25rem;
}
ul > li li,
ol > li li {
  padding-left: 1.5rem;
}

dl.wide-spaced dt, dl.wide-spaced dd {
  float: left;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
dl.wide-spaced dt {
  clear: both;
}
dl dt {
  float: left;
  min-width: 4rem;
  margin-right: 0.5rem;
}
dl dd {
  margin-left: 0;
}

hr {
  border-width: 0;
  border-bottom: 2px solid #115588;
}

img {
  vertical-align: middle;
}

a {
  color: #115588;
}
a img {
  border: 0;
}
a.icon-only, a.no-underline {
  text-decoration: none;
}

button,
a.btn,
input[type=button],
input[type=submit] {
  cursor: pointer;
  display: inline-block;
  padding: 0.075rem 0.38rem;
  background: #4872a9;
  border: 1px solid #4872a9;
  line-height: normal;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
  white-space: nowrap;
  color: white;
}
button:focus-visible,
a.btn:focus-visible,
input[type=button]:focus-visible,
input[type=submit]:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
button[type=submit]:focus-visible,
a.btn[type=submit]:focus-visible,
input[type=button][type=submit]:focus-visible,
input[type=submit][type=submit]:focus-visible {
  outline-width: 2px;
  outline-style: solid;
}
button[disabled],
a.btn[disabled],
input[type=button][disabled],
input[type=submit][disabled] {
  cursor: default;
  background: #757575;
}
button[hidden],
a.btn[hidden],
input[type=button][hidden],
input[type=submit][hidden] {
  display: none;
}
button[aria-expanded=false] + [role=group], button[aria-expanded=false] + [role=listbox], button[aria-expanded=false] + [role=menu],
a.btn[aria-expanded=false] + [role=group],
a.btn[aria-expanded=false] + [role=listbox],
a.btn[aria-expanded=false] + [role=menu],
input[type=button][aria-expanded=false] + [role=group],
input[type=button][aria-expanded=false] + [role=listbox],
input[type=button][aria-expanded=false] + [role=menu],
input[type=submit][aria-expanded=false] + [role=group],
input[type=submit][aria-expanded=false] + [role=listbox],
input[type=submit][aria-expanded=false] + [role=menu] {
  display: none;
}
button[aria-expanded=true] + [role=group], button[aria-expanded=true] + [role=listbox], button[aria-expanded=true] + [role=menu],
a.btn[aria-expanded=true] + [role=group],
a.btn[aria-expanded=true] + [role=listbox],
a.btn[aria-expanded=true] + [role=menu],
input[type=button][aria-expanded=true] + [role=group],
input[type=button][aria-expanded=true] + [role=listbox],
input[type=button][aria-expanded=true] + [role=menu],
input[type=submit][aria-expanded=true] + [role=group],
input[type=submit][aria-expanded=true] + [role=listbox],
input[type=submit][aria-expanded=true] + [role=menu] {
  display: block;
}
button.borderless,
a.btn.borderless,
input[type=button].borderless,
input[type=submit].borderless {
  cursor: pointer;
  background: transparent;
  border-width: 0;
  color: inherit;
}
button.paddingless,
a.btn.paddingless,
input[type=button].paddingless,
input[type=submit].paddingless {
  padding: 0;
}
button.link,
a.btn.link,
input[type=button].link,
input[type=submit].link {
  background: none;
  border: none;
  padding: 0;
  color: #115588;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
}
button.secondary,
a.btn.secondary,
input[type=button].secondary,
input[type=submit].secondary {
  background: #e0771c;
  border-color: #e0771c;
}
button.tertiary,
a.btn.tertiary,
input[type=button].tertiary,
input[type=submit].tertiary {
  background: #5c5c5c;
  border-color: #5c5c5c;
}
button.positive,
a.btn.positive,
input[type=button].positive,
input[type=submit].positive {
  background: #007517;
  border-color: #00420d;
}
button.warning,
a.btn.warning,
input[type=button].warning,
input[type=submit].warning {
  background: #995c00;
  border-color: #995c00;
}

.button-group {
  display: flex;
}
.button-group button,
.button-group a.btn {
  padding: 0.5rem;
  background: white;
  color: #115588;
}
.button-group button:focus,
.button-group a.btn:focus {
  position: relative;
  z-index: 1;
}
.button-group button:first-child,
.button-group a.btn:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}
.button-group button:last-child,
.button-group a.btn:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}
.button-group button[aria-pressed=true], .button-group button[aria-current=true],
.button-group a.btn[aria-pressed=true],
.button-group a.btn[aria-current=true] {
  background: #4872a9;
  color: white;
}
.button-group button[aria-pressed=false]:focus, .button-group button[aria-pressed=false]:hover, .button-group button[aria-current=false]:focus, .button-group button[aria-current=false]:hover,
.button-group a.btn[aria-pressed=false]:focus,
.button-group a.btn[aria-pressed=false]:hover,
.button-group a.btn[aria-current=false]:focus,
.button-group a.btn[aria-current=false]:hover {
  background: #f5f8fb;
}
.button-group.primary button,
.button-group.primary a.btn {
  border-color: white;
  background: #4872a9;
  color: white;
}
.button-group.primary button[aria-pressed=true], .button-group.primary button[aria-current=true],
.button-group.primary a.btn[aria-pressed=true],
.button-group.primary a.btn[aria-current=true] {
  border-color: #4872a9;
  background: white;
  color: #115588;
}

.tooltip {
  cursor: pointer;
  vertical-align: bottom;
}

form {
  margin: 1rem 0;
}
form fieldset {
  margin: 1rem 0;
  border: 2px solid #4872a9;
  line-height: 2;
}
form fieldset legend {
  line-height: normal;
  font-weight: bold;
  color: #115588;
}
form fieldset p:first-child,
form fieldset legend + p {
  margin-top: 0;
}
form fieldset p:last-child {
  margin-bottom: 0;
}
form fieldset label + label,
form [role=group] label + label {
  margin-left: 1rem;
}

label[for],
.label {
  display: inline-block;
  min-width: 100px;
}
label[for].small,
.label.small {
  min-width: 75px;
}
label[for].inline,
.label.inline {
  min-width: 0;
}

input {
  font-family: inherit;
  font-size: inherit;
}
input[type=text], input[type=email] {
  border: 1px solid #757575;
}
input[type=text]:focus-visible, input[type=email]:focus-visible {
  outline: 1px solid #757575;
}

select {
  font-family: inherit;
  font-size: inherit;
}
select:focus-visible {
  outline: 1px solid #757575;
}

.flex {
  display: flex;
  align-items: center;
}
.flex.space-out {
  justify-content: space-between;
}
.flex .column.half {
  width: 50%;
}
.flex .column.one-third {
  width: 33.5%;
}
.flex .column.one-third:last-child {
  width: 33%;
}

.grid {
  display: grid;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table.nowrap,
table.nowrap th,
table.nowrap td,
table .nowrap,
table .nowrap th,
table .nowrap td {
  text-wrap: nowrap;
}
table th,
table td {
  vertical-align: top;
  padding: 0.25rem;
}
table th pre,
table td pre {
  display: inline;
  margin: 0;
}
table th {
  text-align: left;
  color: #115588;
}
table th a.sort {
  text-decoration: none;
  white-space: nowrap;
}
table tbody tr:nth-child(odd):not(.even),
table tbody tr.odd {
  background: rgba(72, 114, 169, 0.15);
}

.pagination {
  padding: 0 1rem;
  border: 2px solid #115588;
  border-bottom-width: 0;
  background: #4872a9;
  margin-top: 1.5rem;
  color: white;
}
.pagination form.column {
  margin: 1rem 0;
}
.pagination select {
  border: 1px solid #115588;
}
.pagination select[name=pagesize] {
  min-width: auto;
}
.pagination button[type=submit] {
  position: relative;
  top: 0.5px;
  padding-top: 2.125px;
  background: #e0771c;
  box-shadow: inset 0 0 0 1px #115588;
  color: white;
}
.pagination button[type=submit]:focus {
  outline-color: white;
}

nav.breadcrumbs ul {
  color: #757575;
}
nav.breadcrumbs ul li {
  display: inline-block;
}
nav.breadcrumbs ul li:not(:first-child)::before {
  content: "›"; /* Chevron */
  margin: 0 0.25rem;
}
nav.breadcrumbs ul li a {
  color: inherit;
}

.badge {
  cursor: default;
  padding: 0.25rem 0.5rem;
  background: #4872a9;
  border-radius: 1rem;
  white-space: nowrap;
  font-weight: normal;
  text-decoration: none !important;
  color: white;
}
.badge.tiny {
  vertical-align: middle;
  padding: 0.125rem 0.25rem;
  font-size: 0.75rem;
}
.badge.green {
  background: #007517;
}
.badge.gray {
  background: #757575;
}

.pill {
  position: relative;
  top: -2px;
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 1rem;
  font-weight: bold;
  text-wrap: nowrap;
  color: white;
}
.pill:not(.icon-only) img:first-child {
  margin-left: -0.25rem;
}
.pill.pill-1 {
  background: #c95f26;
  border: 1px solid #9e4b1e;
}
.pill.pill-2 {
  background: #b6d36a;
  border: 1px solid #667f25;
}
.pill.pill-3 {
  background: #15369d;
  border: 1px solid #030816;
}
.pill.icon-only {
  padding: 0 0.1rem;
}
.pill.icon-only img {
  vertical-align: text-bottom;
}

/** Notifications **/
[role=alert],
[role=status] {
  padding: 0.5rem;
}
[role=alert].error,
[role=status].error {
  background: #ffe0e4;
  border: 1px solid #ad0014;
  color: #ad0014;
}
[role=alert].warning,
[role=status].warning {
  background: #ffebcc;
  border: 1px solid #995c00;
  color: #995c00;
}
[role=alert].success,
[role=status].success {
  background: #c2ffce;
  border: 1px solid #00420d;
  color: #00420d;
}
[role=alert].info,
[role=status].info {
  background: #c2e7ff;
  border: 1px solid #004876;
  color: #004876;
}
[role=alert] p:first-child,
[role=status] p:first-child {
  margin-top: 0;
}
[role=alert] p:last-child,
[role=status] p:last-child {
  margin-bottom: 0;
}
[role=alert] p + p,
[role=status] p + p {
  margin-top: 1rem;
}

.chevron {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}
.chevron.vertical {
  transform: rotate(180deg);
}
.chevron.horizontal {
  transform: rotate(90deg);
}

[aria-expanded=true] .chevron.vertical {
  transform: rotate(0deg);
}
[aria-expanded=true] .chevron.horizontal {
  transform: rotate(180deg);
}

[role=listbox],
[role=menu] {
  z-index: 1;
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #757575;
  box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  background: white;
}
[role=listbox] [role=option],
[role=listbox] [role=menuitem],
[role=menu] [role=option],
[role=menu] [role=menuitem] {
  cursor: pointer;
  padding: 5px 10px;
  white-space: nowrap;
}
[role=listbox] [role=option]:hover,
[role=listbox] [role=menuitem]:hover,
[role=menu] [role=option]:hover,
[role=menu] [role=menuitem]:hover {
  background: #ebebeb;
}
[role=listbox] hr,
[role=menu] hr {
  margin: 0;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.layout {
  width: 1024px;
  margin: 2rem auto;
}
.layout > .notification-global {
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.layout header .flex {
  justify-content: space-between;
}
.layout header .flex .logo:focus-visible {
  outline-offset: 0.5rem;
}
.layout header .flex [role=search] label {
  font-size: 1.25rem;
  color: #757575;
}
.layout header .flex [role=search] label .placeholder {
  display: inline-block;
  position: relative;
  left: 3.5rem; /* Adjust the value as needed */
  top: 50%;
}
.layout header .flex [role=search] label [role=searchbox] {
  width: 15rem;
  border-width: 2px;
  border-radius: 0.25rem;
  padding: 0.25rem;
  padding-left: 3.25rem;
  font-size: 1.25rem;
  color: #757575;
}
.layout header .flex [role=search] button[type=submit] {
  width: 2rem;
  height: 2rem;
  margin-left: 0.25rem;
  padding: 0;
  font-size: 1.25rem;
}
.layout header .flex [role=search] button[type=submit]:focus-visible {
  outline: 2.5px solid #757575;
  border-radius: 0.25rem;
}
.layout header .flex [role=search] button[type=submit] svg path {
  fill: #757575;
}
.layout header .menu-top {
  background: #115588;
  margin-top: 1rem;
  color: white;
}
.layout header .menu-top ul {
  margin: 0;
}
.layout header .menu-top a {
  text-decoration: none;
  color: inherit;
}
.layout header .menu-top button {
  background: transparent;
  border-width: 0;
}
.layout header .menu-top > ul {
  display: flex;
  align-items: center;
}
.layout header .menu-top > ul > li {
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 0;
  background: linear-gradient(to bottom, #668cbe 0%, #4872a9 100%);
  font-weight: bold;
  text-align: center;
}
.layout header .menu-top > ul > li > a, .layout header .menu-top > ul > li > button {
  display: inline-block;
  width: 100%;
  padding: 0.75rem 0;
}
.layout header .menu-top > ul > li > a:focus-visible, .layout header .menu-top > ul > li > button:focus-visible {
  position: relative;
  z-index: 11;
}
.layout header .menu-top > ul > li button[aria-expanded=true] {
  background: linear-gradient(to bottom, #4872a9 0%, #668cbe 100%);
}
.layout header .menu-top > ul > li button[aria-expanded=false]:hover + [role=group],
.layout header .menu-top > ul > li button[aria-expanded=true] + [role=group],
.layout header .menu-top > ul > li [role=group]:hover {
  display: block;
}
.layout header .menu-top > ul > li [role=group] {
  display: none;
  z-index: 10;
  position: absolute;
  background: #4872a9;
  text-align: left;
}
.layout header .menu-top > ul > li [role=group] ul {
  border-top: 1px solid #668cbe;
}
.layout header .menu-top > ul > li [role=group] ul li {
  padding-left: 0;
}
.layout header .menu-top > ul > li [role=group] ul li button,
.layout header .menu-top > ul > li [role=group] ul li a {
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  font-weight: normal;
}
.layout header .menu-top > ul > li [role=group] ul li button:focus, .layout header .menu-top > ul > li [role=group] ul li button:hover,
.layout header .menu-top > ul > li [role=group] ul li a:focus,
.layout header .menu-top > ul > li [role=group] ul li a:hover {
  background: #547fb6;
}
.layout header .menu-top > ul > li + li {
  border-left: 2px solid #4872a9;
}
.layout .banner {
  border-bottom: 2px solid #4872a9;
}
.layout main {
  border-bottom: 2px solid #115588;
}
.layout main aside {
  padding: 0 1rem;
  margin-bottom: 2rem;
  background: #4872a9;
  border-radius: 0.5rem;
  color: white;
}
.layout main aside h1,
.layout main aside h2,
.layout main aside h3,
.layout main aside p,
.layout main aside a {
  color: white;
}
.layout main input[type=text],
.layout main input[type=email],
.layout main select {
  min-width: 250px;
}
.layout footer .column {
  align-self: flex-start;
  padding: 1.5rem;
}
.layout footer .column :first-child {
  margin-top: 0;
}
.layout footer .column :last-child {
  margin-bottom: 0;
}
.layout footer ul li {
  line-height: 1.5;
}
.layout footer .powered-by {
  margin-top: 3rem;
  text-align: center;
}
.layout footer .powered-by h3 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #666666;
}
.layout footer .powered-by svg g {
  fill: gray;
}

.skip-to-content, .skip-to-content:focus, .skip-to-content:active {
  display: block;
  background: white;
  margin: auto !important;
  padding: 0.5rem !important;
  border: 2px solid black !important;
  border-radius: 0.5rem;
}

.hidden-placeholder {
  visibility: hidden;
}

[data-mouse-click] {
  cursor: pointer;
}

body.frontpage .grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  color: white;
}
body.frontpage .grid-item {
  height: 280px;
}
body.frontpage .grid-item h1, body.frontpage .grid-item h2, body.frontpage .grid-item h3 {
  color: inherit;
}
body.frontpage .slideshow .slide {
  position: absolute;
  display: none;
}
body.frontpage .slideshow .slide:nth-child(1) {
  z-index: 2;
  display: block;
}
body.frontpage .slideshow .slide:nth-child(2) {
  z-index: 1;
  display: block;
}
body.frontpage .grid-item-1 {
  grid-area: 1/1/2/3;
  overflow: hidden;
  background: black;
}
body.frontpage .grid-item-2 {
  grid-area: 1/3/3/4;
  height: 560px;
  background: #e89247;
}
body.frontpage .grid-item-2 .flex {
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
}
body.frontpage .grid-item-2 .flex .row:first-child {
  justify-content: flex-start;
  height: 90%;
  padding: 1.25rem 1.5rem;
  text-align: left;
}
body.frontpage .grid-item-2 .flex .row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 10%;
  text-align: center;
}
body.frontpage .grid-item-2 h2 {
  margin-top: 0;
}
body.frontpage .grid-item-2 a {
  text-decoration: none;
  color: inherit;
}
body.frontpage .grid-item-2 ul {
  margin: 0;
}
body.frontpage .grid-item-2 ul li {
  font-size: 0.85rem;
  font-weight: bold;
}
body.frontpage .grid-item-2 ul li + li {
  padding-top: 1rem;
}
body.frontpage .grid-item-2 .cases-link {
  border-top: 2px solid #e0771c;
  background: linear-gradient(to bottom, #eb9f5e, #e68a39);
  font-size: 1.25rem;
  font-weight: bold;
}
body.frontpage .grid-item-3 {
  grid-area: 2/1/3/2;
  background: linear-gradient(to bottom, #eb9f5e, #e68a39);
  font-size: 1.5rem;
  text-align: center;
}
body.frontpage .grid-item-3 .flex {
  width: 100%;
  height: 33%;
}
body.frontpage .grid-item-3 .flex:first-child {
  height: 20%;
  margin-top: 1rem;
}
body.frontpage .grid-item-3 h2 {
  width: 100%;
  font-size: 1.5rem;
}
body.frontpage .grid-item-3 .social-link {
  display: block;
  width: 100%;
}
body.frontpage .grid-item-3 .social-link img {
  width: 66%;
}
body.frontpage .grid-item-4 {
  grid-area: 2/2/3/3;
  background-image: linear-gradient(to bottom, #668cbe 0%, #4872a9 100%);
}
body.frontpage .grid-item-4 .flex {
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
}
body.frontpage .grid-item-4 .flex .row:first-child {
  justify-content: center;
  height: 35%;
  font-weight: bold;
  text-align: center;
}
body.frontpage .grid-item-4 .flex .row {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 65%;
  text-align: left;
}
body.frontpage .grid-item-4 p {
  z-index: 1;
  position: relative;
  font-size: 1.05rem;
}
body.frontpage .grid-item-4 .background-photo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45%;
}
body.frontpage .grid-item-4 .app-link {
  z-index: 1;
  position: relative;
  display: block;
  margin: 1rem;
  margin-top: 0.5rem;
}

body.cases form.filter .flex {
  justify-content: space-between;
}
body.cases .status-indicator {
  margin-top: -2px;
}

body.case-single .case-container {
  align-items: flex-start;
  justify-content: space-between;
}
body.case-single .case-container dl.case-details {
  width: 80%;
  margin-top: 0;
}
body.case-single .case-container dl.case-details dt {
  min-width: 5rem;
}
body.case-single .case-container aside {
  min-width: 20%;
}

body.departments main .flex {
  justify-content: space-between;
  margin-bottom: 1rem;
}
body.departments main .flex .map-column {
  position: relative;
}
body.departments main .flex .map-column .map {
  display: inline-block;
  margin-left: 1rem;
}
body.departments main .flex .map-column a.map-marker {
  position: absolute;
  text-decoration: none;
}
body.departments main .flex .map-column a.map-marker img {
  background: white;
  border: 2px solid white;
  border-radius: 5px;
}
body.departments main .flex .details-column {
  width: 350px;
}
body.departments main .flex .details-column h2 img {
  vertical-align: text-bottom;
}

body.employees .grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 25px 10px;
  margin-bottom: 1rem;
}
body.employees .grid .portrait {
  float: left;
  margin-right: 0.5rem;
  border: 1px solid #115588;
}
body.employees .grid h2 {
  margin: 0;
  font-size: 1rem;
}

body.employee-single h1 {
  margin-bottom: 0;
}
body.employee-single .job-title {
  font-weight: bold;
}

body.news main .flex {
  align-items: flex-start;
}
body.news main .flex .overview h2:first-child {
  margin-top: 0;
}
body.news main .flex .overview h2 {
  margin-bottom: 0;
}
body.news main .flex .overview .time {
  font-style: italic;
}
body.news main .flex aside {
  width: 600px;
}