body.news {
	main {
		.flex {
			align-items: flex-start;

			.overview {
				h2:first-child {
					margin-top: 0;
				}

				h2 {
					margin-bottom: 0;
				}

				.time {
					font-style: italic;
				}
			}

			aside {
				width: 600px;
			}
		}
	}
}
