body.case-single {
	.case-container {
		align-items: flex-start;
		justify-content: space-between;

		dl.case-details {
			width: 80%;
			margin-top: 0;

			dt {
				min-width: 5rem;
			}
		}

		aside {
			min-width: 20%;
		}
	}
}