body.frontpage {
	.grid {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		color: white;
	}

	.grid-item {
		height: 280px;

		h1, h2, h3 {
			color: inherit;
		}
	}

.slideshow {
	.slide {
		position: absolute;
		display: none;
	}

	.slide:nth-child(1) {
		z-index: 2;
		display: block;
	}

	.slide:nth-child(2) {
		z-index: 1;
		display: block;
	}
}

	.grid-item-1 {
		grid-area: 1 / 1 / 2 / 3;
		overflow: hidden;
		background: black;
	}
	.grid-item-2 {
		grid-area: 1 / 3 / 3 / 4;
		height: 560px;
		background: $color-secondary;

		.flex {
			flex-wrap: wrap;
			flex-direction: column;
			height: 100%;

			.row:first-child {
				justify-content: flex-start;
				height: 90%;
				padding: 1.25rem 1.5rem;
				text-align: left;
			}

			.row {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				height: 10%;
				text-align: center;
			}
		}

		h2 {
			margin-top: 0;
		}

		a {
			text-decoration: none;
			color: inherit;
		}

		ul {
			margin: 0;

			li {
				font-size: $font-regular-smaller;
				font-weight: bold;

				& + li {
					padding-top: 1rem;
				}
			}
		}

		.cases-link {
			border-top: 2px solid darken($color-secondary, 10%);
			background: $color-secondary-gradient;
			font-size: $font-regular-larger;
			font-weight: bold;
		}
	}
	.grid-item-3 {
		grid-area: 2 / 1 / 3 / 2;
		background: linear-gradient(to bottom, lighten($color-secondary, 5%), darken($color-secondary, 3%));
		font-size: $font-regular-largest;
		text-align: center;

		.flex {
			width: 100%;
			height: 33%;

			&:first-child {
				height: 20%;
				margin-top: 1rem;
			}
		}

		h2 {
			width: 100%;
			font-size: $font-regular-largest;
		}

		.social-link {
			display: block;
			width: 100%;

			img {
				width: 66%;
			}
		}
	}
	.grid-item-4 {
		grid-area: 2 / 2 / 3 / 3;
		background-image: $color-primary-gradient;

		.flex {
			flex-wrap: wrap;
			flex-direction: column;
			height: 100%;

			.row:first-child {
				justify-content: center;
				height: 35%;
				font-weight: bold;
				text-align: center;
			}

			.row {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				width: 100%;
				height: 65%;
				text-align: left;
			}
		}

		p {
			z-index: 1;
			position: relative;
			font-size: 1.05rem;
		}

		.background-photo {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 45%;
		}

		.app-link {
			z-index: 1;
			position: relative;
			display: block;
			margin: 1rem;
			margin-top: .5rem;
		}
	}
}
