body.departments {
	main {
		.flex {
			justify-content: space-between;
			margin-bottom: 1rem;

			.map-column {
				position: relative;

				.map {
					display: inline-block;
					margin-left: 1rem;
				}

				a.map-marker {
					position: absolute;
					text-decoration: none;

					img {
						background: white;
						border: 2px solid white;
						border-radius: 5px;
					}
				}
			}

			.details-column {
				width: 350px;

				h2 {
					img {
						vertical-align: text-bottom;
					}
				}
			}
		}
	}
}
