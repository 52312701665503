body.cases {
	form.filter {
		.flex {
			justify-content: space-between;
		}
	}

	.status-indicator {
		margin-top: -2px;
	}
}