.skip-to-content {
	&,
	&:focus,
	&:active {
		display: block;
		background: white;
		margin: auto !important;
		padding: .5rem !important;
		border: 2px solid black !important;
		border-radius: .5rem;
	}
}

.hidden-placeholder {
	visibility: hidden;
}

[data-mouse-click] {
	cursor: pointer;
}
