body.employees {
	.grid {
		grid-template-columns: repeat(3, 1fr);
		gap: 25px 10px;
		margin-bottom: 1rem;

		.portrait {
			float: left;
			margin-right: .5rem;
			border: 1px solid $color-text-primary;
		}

		h2 {
			margin: 0;
			font-size: 1rem;
		}
	}
}