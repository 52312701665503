$font-regular-smallest: .75rem;
$font-regular-smaller: .85rem;
$font-regular-normal: 1rem;
$font-regular-larger: 1.25rem;
$font-regular-largest: 1.5rem;

$color-green: #007517;
$color-gray: #757575;
$color-text-primary: #115588;
$color-form-control: $color-gray;
$color-primary: #4872a9;
$color-primary-gradient: linear-gradient(to bottom, lighten($color-primary, 10%) 0%, $color-primary 100%);
$color-primary-gradient-reverse: linear-gradient(to bottom, $color-primary 0%, lighten($color-primary, 10%) 100%);
$color-layout-separator: #4872a9;
$color-secondary: #e89247;
$color-secondary-dark: darken($color-secondary, 10%);
$color-secondary-gradient: linear-gradient(to bottom, lighten($color-secondary, 5%), darken($color-secondary, 3%));
$color-secondary-border: darken(#e89247, 25%);
$color-tertiary: darken($color-form-control, 10%);
$color-tertiary-border: $color-tertiary;
$color-positive: $color-green;
$color-positive-border: darken($color-green, 10%);
$color-notification-error: lighten(#e0001a, 50%);
$color-notification-error-text: darken(#e0001a, 10%);
$color-notification-warning: lighten(#ff9900, 40%);
$color-notification-warning-text: darken(#ff9900, 20%);
$color-notification-success: lighten($color-green, 65%);
$color-notification-success-text: darken($color-green, 10%);
$color-notification-info: lighten(#0077c2, 50%);
$color-notification-info-text: darken(#0077c2, 15%);
$color-pill-1: #c95f26;
$color-pill-2: #b6d36a;
$color-pill-3: #15369d;

$color-dashboard-content-background: #f3f3f3;
$color-dashboard-interface: darken($color-text-primary, 20%);
$color-dashboard-interface-hover: lighten($color-dashboard-interface, 8%);
$color-dashboard-interface-text: darken(white, 20%);
$color-dashboard-interface-text-light: darken(white, 8%);
$color-dashboard-pre-border: $color-form-control;
$color-dashboard-pre-background: lighten($color-dashboard-pre-border, 40%);

$box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.25);

$transition-color-property: color;
$transition-color-duration: 0.3s;
$transition-color-timing-function: ease;

$transition-transform-property: transform;
$transition-transform-duration: 0.3s;
$transition-transform-timing-function: ease-in-out;

@mixin color-transition($properties: $transition-color-property, $duration: $transition-color-duration, $timing-function: $transition-color-timing-function) {
	transition: $properties $duration $timing-function;
}

@mixin transform-transition($properties: $transition-transform-property, $duration: $transition-transform-duration, $timing-function: $transition-transform-timing-function) {
	transition: $properties $duration $timing-function;
}
