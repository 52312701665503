*:focus-visible {
	outline-offset: 1px;
}

h1, h2, h3 {
	color: $color-text-primary;
}

h1 {
	font-size: 1.5rem;
}
h2 {
	font-size: 1.25rem;
}
h3 {
	font-size: 1rem;
}

p,
.p {
	margin: 1.12em 0;
	line-height: 1.5;
}

ul,
ol {
	padding-left: 1rem;

	&ul {
		list-style: circle;
	}

	&.unstyled-list {
		list-style: none;
		padding: 0;
	}

	&.wide-spaced {
		li {
			margin: .5rem 0;
		}
	}

	> li {
		padding-left: .25rem;

		li {
			padding-left: 1.5rem;
		}
	}
}

dl {
	&.wide-spaced {
		dt, dd {
			float: left;
			display: block;
			margin-top: .5rem;
			margin-bottom: .5rem;
		}

		dt {
			clear: both;
		}
	}

	dt {
		float: left;
		min-width: 4rem;
		margin-right: 0.5rem;
	}

	dd {
		margin-left: 0;
	}
}

hr {
	border-width: 0;
	border-bottom: 2px solid $color-text-primary;
}

img {
	vertical-align: middle;
}

a {
	color: $color-text-primary;

	img {
		border: 0;
	}

	&.icon-only,
	&.no-underline {
		text-decoration: none;
	}
}

button,
a.btn,
input[type="button"],
input[type="submit"] {
	cursor: pointer;
	display: inline-block;
	padding: .075rem .38rem;
	background: $color-primary;
	border: 1px solid $color-primary;
	line-height: normal;
	font-size: inherit;
	font-weight: inherit;
	text-decoration: none;
	white-space: nowrap;
	color: white;

	&:focus-visible {
		outline: -webkit-focus-ring-color auto 1px;
	}

	&[type="submit"]:focus-visible {
		outline-width: 2px;
		outline-style: solid;
	}

	&[disabled] {
		cursor: default;
		background: $color-form-control;
	}

	&[hidden] {
		display: none;
	}

	&[aria-expanded="false"] {
		& + [role="group"],
		& + [role="listbox"],
		& + [role="menu"] {
			display: none;
		}
	}
	&[aria-expanded="true"] {
		& + [role="group"],
		& + [role="listbox"],
		& + [role="menu"] {
			display: block;
		}
	}

	&.borderless {
		cursor: pointer;
		background: transparent;
		border-width: 0;
		color: inherit;
	}

	&.paddingless {
		padding: 0;
	}

	&.link {
		background: none;
		border: none;
		padding: 0;
		color: $color-text-primary;
		text-decoration: underline;
		cursor: pointer;
		font-size: inherit;
		font-family: inherit;
	}	

	&.secondary {
		background: $color-secondary-dark;
		border-color: $color-secondary-dark;
	}

	&.tertiary {
		background: $color-tertiary;
		border-color: $color-tertiary-border;
	}

	&.positive {
		background: $color-positive;
		border-color: $color-positive-border;
	}

	&.warning {
		background: $color-notification-warning-text;
		border-color: $color-notification-warning-text;
	}
}

.button-group {
	display: flex;

	button,
	a.btn {
		padding: .5rem;
		background: white;
		color: $color-text-primary;

		&:focus {
			position: relative;
			z-index: 1;
		}

		&:first-child {
			border-radius: .5rem 0 0 .5rem;
		}

		&:last-child {
			border-radius: 0 .5rem .5rem 0;
		}

		&[aria-pressed="true"],
		&[aria-current="true"] {
			background: $color-primary;
			color: white;
		}

		&[aria-pressed="false"],
		&[aria-current="false"] {
			&:focus,
			&:hover {
				background: lighten($color-primary, 50%);
			}
		}
	}

	&.primary {
		button,
		a.btn {
			border-color: white;
			background: $color-primary;
			color: white;

			&[aria-pressed="true"],
			&[aria-current="true"] {
				border-color: $color-primary;
				background: white;
				color: $color-text-primary;
			}
		}
	}
}

.tooltip {
	cursor: pointer;
	vertical-align: bottom;
}

form {
	margin: 1rem 0;

	fieldset {
		margin: 1rem 0;
		border: 2px solid $color-primary;
		line-height: 2;
	
		legend {
			line-height: normal;
			font-weight: bold;
			color: $color-text-primary;
		}

		p:first-child,
		legend + p {
			margin-top: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	fieldset,
	[role="group"] {
		label + label {
			margin-left: 1rem;
		}
	}
}

label[for],
.label {
	display: inline-block;
	min-width: 100px;

	&.small {
		min-width: 75px;
	}

	&.inline {
		min-width: 0;
	}
}

input {
	font-family: inherit;
	font-size: inherit;

	&[type="text"],
	&[type="email"] {
		border: 1px solid $color-form-control;

		&:focus-visible {
			outline: 1px solid $color-form-control;
		}
	}
}

select {
	font-family: inherit;
	font-size: inherit;

	&:focus-visible {
		outline: 1px solid $color-form-control;
	}
}

.flex {
	display: flex;
	align-items: center;

	&.space-out {
		justify-content: space-between;
	}

	.column {
		&.half {
			width: 50%;
		}

		&.one-third {
			width: 33.5%;
		}

		&.one-third:last-child {
			width: 33%;
		}
	}
}

.grid {
	display: grid;
	grid-column-gap: 0;
	grid-row-gap: 0;
}

table {
	width: 100%;
	border-collapse: collapse;

	&.nowrap,
	.nowrap {
		&,
		th,
		td {
			text-wrap: nowrap;
		}
	}

	th,
	td {
		vertical-align: top;
		padding: .25rem;

		pre {
			display: inline;
			margin: 0;
		}
	}

	th {
		text-align: left;
		color: $color-text-primary;

		a.sort {
			text-decoration: none;
			white-space: nowrap;
		}
	}

	tbody {
		tr:nth-child(odd):not(.even),
		tr.odd {
			background: rgba($color-primary, 0.15);
		}
	}
}

.pagination {
	padding: 0 1rem;
	border: 2px solid $color-text-primary;
	border-bottom-width: 0;
	background: $color-primary;
	margin-top: 1.5rem;
	color: white;

	form.column {
		margin: 1rem 0;
	}

	select {
		border: 1px solid $color-text-primary;
	}

	select[name="pagesize"] {
		min-width: auto;
	}

	button[type="submit"] {
		position: relative;
		top: .5px;
		padding-top: 2.125px;
		background: $color-secondary-dark;
		box-shadow: inset 0 0 0 1px $color-text-primary; // Using `border` adds too much height
		color: white;

		&:focus {
			outline-color: white;
		}
	}
}

nav.breadcrumbs {
	ul {
		color: $color-form-control;

		li {
			display: inline-block;

			&:not(:first-child)::before {
				content: "\203A"; /* Chevron */
				margin: 0 .25rem;
			}

			a {
				color: inherit;
			}
		}
	}
}

.badge {
	cursor: default;
	padding: .25rem .5rem;
	background: $color-primary;
	border-radius: 1rem;
	white-space: nowrap;
	font-weight: normal;
	text-decoration: none !important;
	color: white;

	&.tiny {
		vertical-align: middle;
		padding: .125rem .25rem;
		font-size: .75rem;
	}

	&.green {
		background: $color-green;
	}

	&.gray {
		background: $color-gray;
	}
}

.pill {
	position: relative;
	top: -2px;
	display: inline-block;
	padding: 0 .5rem;
	border-radius: 1rem;
	font-weight: bold;
	text-wrap: nowrap;
	color: white;

	&:not(.icon-only) {
		img:first-child {
			margin-left: -.25rem;
		}
	}

	&.pill-1 {
		background: $color-pill-1;
		border: 1px solid darken($color-pill-1, 10%);
	}

	&.pill-2 {
		background: $color-pill-2;
		border: 1px solid darken($color-pill-2, 30%);
	}

	&.pill-3 {
		background: $color-pill-3;
		border: 1px solid darken($color-pill-3, 30%);
	}

	&.icon-only {
		padding: 0 .1rem;

		img {
			vertical-align: text-bottom;
		}
	}
}

/** Notifications **/
[role="alert"],
[role="status"] {
	padding: .5rem;

	&.error {
		background: $color-notification-error;
		border: 1px solid $color-notification-error-text;
		color: $color-notification-error-text;
	}

	&.warning {
		background: $color-notification-warning;
		border: 1px solid $color-notification-warning-text;
		color: $color-notification-warning-text;
	}

	&.success {
		background: $color-notification-success;
		border: 1px solid $color-notification-success-text;
		color: $color-notification-success-text;
	}

	&.info {
		background: $color-notification-info;
		border: 1px solid $color-notification-info-text;
		color: $color-notification-info-text;
	}

	p:first-child {
		margin-top: 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	p + p {
		margin-top: 1rem;
	}
}

.chevron {
	display: inline-block;

	@include transform-transition();

	&.vertical {
		transform: rotate(180deg);
	}

	&.horizontal {
		transform: rotate(90deg);
	}
}

[aria-expanded="true"] {
	.chevron {
		&.vertical {
			transform: rotate(0deg);
		}

		&.horizontal {
			transform: rotate(180deg);
		}
	}
}

[role="listbox"],
[role="menu"] {
	z-index: 1;
	position: absolute;
	list-style: none;
	margin: 0;
	padding: 0;
	border: 1px solid $color-form-control;
	box-shadow: $box-shadow;
	background: white;

	[role="option"],
	[role="menuitem"] {
		cursor: pointer;
		padding: 5px 10px;
		white-space: nowrap;

		&:hover {
			background: $color-dashboard-interface-text-light;
		}
	}

	hr {
		margin: 0;
	}
}
