* {
	box-sizing: border-box;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

/* Mobile-first approach */
.mobile-only {
	display: block; /* Show on mobile */
}

.desktop-only {
	display: none; /* Hide on mobile */
}

/* Show .desktop-only and hide .mobile-only on larger screens */
@media (min-width: 768px) {
	.mobile-only {
		display: none;
	}
	.desktop-only {
		display: block;
	}
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important; /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important; /* 3 */
}

.sr-only:focus,
.sr-only:active {
	z-index: 1;
	clip: auto !important;
	-webkit-clip-path: none !important;
		clip-path: none !important;
	height: auto !important;
	margin: auto !important;
	overflow: visible !important;
	width: auto !important;
	white-space: normal !important;
}
